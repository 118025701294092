import FormButton from "../components/form/Button";
import errorStyles from "../styles/error.module.css";
import detailStyles from "../styles/partials/detail.module.css";
import { useRouter } from "next/router";

export default function Custom404() {
  const router = useRouter();
  return (
    <div
      className={
        errorStyles["container"] + " " + detailStyles["container-detail"]
      }
    >
      <div className={errorStyles["code"]}>404</div>
      <p className={errorStyles["summary"]}>Sorry! This page does not exist.</p>
      <FormButton
        onClick={() => router.push("/")}
        styles={errorStyles["button"]}
      >
        Go Home
      </FormButton>
    </div>
  );
}
